// import classNames from "classnames";
// import { Toaster } from "react-hot-toast";

// // routes
// import MainRoutes from "./routes/Routes";

// // hooks
// import { useLogoutUser } from "hooks/useLogoutUser/useLogoutUser";

// // store
// import { useCore } from "store/useCore/useCore";

// // providers
// import QueryProvider from "./providers/QueryProvider/QueryProvider";

// // types
// import { Theme } from "./store/useCore/types";

// // assets
// import "assets/styles/main.scss";

// const App = () => {
//   const theme = useCore((state) => state.theme);
//   useLogoutUser();

//   return (

//     <div className={classNames("app", { dark: theme === Theme.DARK })}>
//       <QueryProvider>
//         <MainRoutes />
//       </QueryProvider>
//       <Toaster
//         position="top-right"
//         reverseOrder={false}
//         toastOptions={{
//           error: {
//             style: {
//               background: "#FF4747",
//               color: "#fff",
//             },
//           },
//           success: {
//             style: {
//               background: "#22C55E",
//               color: "#fff",
//             },
//           },
//         }}
//       />
//     </div>
//   );
// };

// export default App;

// assets
import ComingSoon from "assets/img/coming-soon.svg";

const App = () => {
  return (
    <div className="min-h-screen">
      <img src={ComingSoon} alt="soon" className="w-full" />
    </div>
  );
};

export default App;
